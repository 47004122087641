import Record from '@vuex-orm/core/dist/src/data/Record';

import DocumentCoreModel from '@/modules/documents/models/DocumentCoreModel';
import DocumentItemCoreModel from '@/modules/documents/models/DocumentItemCoreModel';
import SaleInvoiceCoreModel from '@/modules/documents/models/SaleInvoiceCoreModel';
import DocumentTypesEnum from '@/modules/documents/enums/DocumentTypesEnum';
import AmountsInterface from '@/shared/lib/interfaces/AmountsInterface';
import { calculateDocumentTotals } from '@/modules/documents/utils/DocumentUtils';

export default class SaleCorrection extends DocumentCoreModel {
  /**
   * Constructor
   * @param record
   */
  constructor(record?: Record) {
    super(record);

    this.type = DocumentTypesEnum.SALE_INVOICE_CORRECTION;
  }

  /**
   * Get calculate dynamic totals
   */
  public get dynamicTotals(): AmountsInterface {
    if (!this.relatedDocument) {
      throw Error('No related document loaded!');
    }

    const totals: AmountsInterface = calculateDocumentTotals(this.documentItems, this.hasGrossPrices);
    const relatedDocumentTotals: AmountsInterface = this.relatedDocument.dynamicTotals;

    return {
      net: totals.net - (this.relatedDocument.isPaid ? relatedDocumentTotals.net : 0),
      vat: totals.vat - (this.relatedDocument.isPaid ? relatedDocumentTotals.vat : 0),
      gross: totals.gross - (this.relatedDocument.isPaid ? relatedDocumentTotals.gross : 0),
    };
  }

  /**
   * Check if has any document items quantity
   */
  public get hasAnyDocumentItemsQuantity(): boolean {
    return this.documentItemsTotalQuantity > 0;
  }

  /**
   * Calculate document items total quantity
   */
  public get documentItemsTotalQuantity(): number {
    return this.documentItems.reduce((total: number, documentItem: DocumentItemCoreModel) => {
      total += documentItem.quantity || 0;
      return total;
    }, 0);
  }

  /**
   * Sync data from sale invoice
   * @param saleInvoice
   */
  public syncSaleInvoice(saleInvoice: SaleInvoiceCoreModel) {
    this.syncDocumentItems(saleInvoice.documentItems);

    this.dateSale = saleInvoice.dateSale;
    this.datePayment = saleInvoice.datePayment;

    this.payment = saleInvoice.payment;
    this.prices = saleInvoice.prices;
    this.currency = saleInvoice.currency;

    this.relatedDocumentId = saleInvoice.id;
    this.relatedDocument = saleInvoice;

    this.contractor = saleInvoice.contractor;
    this.contractorId = saleInvoice.contractorId;

    this.documentData = saleInvoice.documentData;
    this.documentDataId = saleInvoice.documentDataId;
  }

  /**
   * Reset document items quantities
   */
  public resetDocumentItemsQuantities() {
    this.documentItems.forEach((documentItem: DocumentItemCoreModel) => {
      documentItem.quantity = 0;
    });
  }
}
